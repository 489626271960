<script setup lang="ts">
import type { Link } from "~/types/common";

const isMenuButtonHidden = ref(false);
const isMobileMenuOpen = ref(false);
const isTopLinksOpen = ref(false);
const mainMenuDisplay = ref("");

function onMenuButtonClick() {
  isMenuButtonHidden.value = !isMenuButtonHidden.value;
  isMobileMenuOpen.value = !isMobileMenuOpen.value;
  isTopLinksOpen.value = !isTopLinksOpen.value;
  mainMenuDisplay.value = mainMenuDisplay.value === "block" ? "" : "block";
}

const { locale, locales, setLocale } = useI18n();

const localeInfo = computed(() => {
  return (locales.value as any as { code: string }[]).find(
    (l: { code: string }) => l.code === locale.value,
  );
});

interface HeaderData {
  loginButtonText: string;
  signupButtonText: string;
  loginButtonDropdown: {
    id: number;
    name: string;
    url: string;
    openInNewTab: boolean;
  }[];
}

interface NavItemsData {
  id: number;
  attributes: {
    name: string;
    content: ContentItem[];
  };
}

interface ContentItem {
  id: number;
  __component: string;
  links: Link[];
  name?: string;
  icon?: {
    data: {
      id: number;
      attributes: {
        url: string;
      };
    };
  };
}

const { data: navItems } = useCustomFetch<NavItemsData>(
  "/api/strapi/header-nav-items",
);
const { data } = useCustomFetch<HeaderData>("/api/strapi/header");

const sortedNavItems = computed(() => {
  return navItems.value?.sort(
    (a, b) => a.attributes.menu_position - b.attributes.menu_position,
  );
});

const commonData = inject("commonData");
</script>

<template>
  <section v-if="data && sortedNavItems && commonData" id="header">
    <div class="container">
      <div class="row">
        <!-- Logo and Language Selector -->
        <div class="col-md-4">
          <NuxtLinkLocale to="/" class="logo">
            <img :src="$getStrapiImageSrc(commonData.logoColored)" />
          </NuxtLinkLocale>
          <div class="lang">
            <span class="d-inline-flex align-items-center">
              <span
                :class="`fi fis rounded-circle fi-${localeInfo?.language}`"
                style="font-size: 26px"
              ></span>
              <span class="langcode">{{ localeInfo?.code.toUpperCase() }}</span>
            </span>

            <div class="lang-options">
              <div v-for="loc in locales" :key="loc.code">
                <a
                  class="d-flex align-items-center"
                  @click.prevent.stop="setLocale(loc.code)"
                >
                  <span
                    :class="`fi fis rounded-circle fi-${loc?.language}`"
                    style="font-size: 26px"
                  ></span>
                  &nbsp; {{ loc.name }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <!-- Main Menu and Buttons -->
        <div class="col-md-8">
          <div class="d-flex justify-content-xl-end w-100 gap-3">
            <a
              id="menubutton"
              :class="{ hidden: isMenuButtonHidden }"
              @click="onMenuButtonClick"
            />
            <ul
              class="mainmenu w-100 d-xl-inline-flex justify-content-xl-end"
              :class="{ show: isMobileMenuOpen }"
              :style="{ display: mainMenuDisplay }"
            >
              <!-- Main Menu Items -->
              <li class="abc only-tablet">
                <ul
                  class="mainmenu w-100"
                  :class="{ show: isMobileMenuOpen }"
                  :style="{ display: mainMenuDisplay }"
                >
                  <li id="tablet-login-btn" class="menu-item-has-children">
                    <a class="buttons outline" href="#">{{
                      data.loginButtonText
                    }}</a>
                    <ul class="sub-menu">
                      <li
                        v-for="item in data.loginButtonDropdown"
                        :key="item.id"
                      >
                        <NuxtLinkLocale
                          :to="item.url"
                          :target="item.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ item.name }}
                        </NuxtLinkLocale>
                      </li>
                    </ul>
                  </li>
                </ul>
                <NuxtLinkLocale class="buttons inline" href="/register">{{
                  data.signupButtonText
                }}</NuxtLinkLocale>
              </li>

              <li
                v-for="item in sortedNavItems"
                :key="item.id"
                class="menu-item-has-children"
              >
                <a href="#">{{ item.attributes.name }}</a>
                <ul
                  class="sub-menu"
                  :class="{ showblocks: item.attributes.content.length > 1 }"
                >
                  <template
                    v-for="content in item.attributes.content"
                    :key="`content_${content.id}`"
                  >
                    <template v-if="content.__component === 'header.nav-group'">
                      <li
                        v-for="link in content.links"
                        :key="`link_${link.id}`"
                      >
                        <NuxtLinkLocale
                          :to="link.url"
                          :target="link.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ link.name }}
                        </NuxtLinkLocale>
                      </li>
                    </template>

                    <div
                      v-if="content.__component === 'header.nav-column'"
                      class="colblock"
                      :style="{
                        '--_icon': content.icon
                          ? `url(${$getStrapiImageSrc(content.icon)})`
                          : 'none',
                      }"
                    >
                      <li style="padding-right: 10px">
                        <span
                          style="text-wrap: nowrap"
                          v-html="$mdRenderer.renderInline(content.name)"
                        ></span>
                      </li>
                      <li
                        v-for="columnLink in content.links"
                        :key="`column_link_${columnLink.id}`"
                      >
                        <NuxtLinkLocale
                          :to="columnLink.url"
                          :target="columnLink.openInNewTab ? '_blank' : '_self'"
                        >
                          {{ columnLink.name }}
                        </NuxtLinkLocale>
                      </li>
                    </div>
                  </template>
                </ul>
              </li>
            </ul>
            <div
              id="header-login-signup"
              class="d-flex gap-3 flex-nowrap justify-content-end"
            >
              <ul class="mainmenu">
                <li id="tablet-login-btn" class="menu-item-has-children">
                  <a class="buttons outline text-nowrap" href="#">{{
                    data.loginButtonText
                  }}</a>
                  <ul class="sub-menu">
                    <li v-for="item in data.loginButtonDropdown" :key="item.id">
                      <NuxtLinkLocale
                        :to="item.url"
                        :target="item.openInNewTab ? '_blank' : '_self'"
                      >
                        {{ item.name }}
                      </NuxtLinkLocale>
                    </li>
                  </ul>
                </li>
              </ul>
              <div class="toplinks" :class="{ open: isTopLinksOpen }">
                <NuxtLinkLocale
                  class="buttons inline text-nowrap"
                  href="/register"
                  >{{ data.signupButtonText }}</NuxtLinkLocale
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
