import { default as aboutKJAmqyq7bDMeta } from "/usr/src/app/pages/about.vue?macro=true";
import { default as account_45typesXV6EQNvJBlMeta } from "/usr/src/app/pages/account-types.vue?macro=true";
import { default as affiliateFbVz15BTjIMeta } from "/usr/src/app/pages/affiliate.vue?macro=true";
import { default as appRd98Yy2z3KMeta } from "/usr/src/app/pages/app.vue?macro=true";
import { default as careersBYBSYLfIj4Meta } from "/usr/src/app/pages/careers.vue?macro=true";
import { default as cfd_45asset_45listFqJgWMivsKMeta } from "/usr/src/app/pages/cfd-asset-list.vue?macro=true";
import { default as cfd_45tradingBxGYiFFgwoMeta } from "/usr/src/app/pages/cfd-trading.vue?macro=true";
import { default as commoditiesFZyLNwnILhMeta } from "/usr/src/app/pages/commodities.vue?macro=true";
import { default as contactkLj0iaDgVSMeta } from "/usr/src/app/pages/contact.vue?macro=true";
import { default as cookie_45disclosuresJPtNsqZQKMeta } from "/usr/src/app/pages/cookie-disclosure.vue?macro=true";
import { default as copy_45tradingZT5FV7T36AMeta } from "/usr/src/app/pages/copy-trading.vue?macro=true";
import { default as cryptoI4lBny2PSeMeta } from "/usr/src/app/pages/crypto.vue?macro=true";
import { default as _91slug_9380QFxMP33bMeta } from "/usr/src/app/pages/education-authors/[slug].vue?macro=true";
import { default as _91slug_93HOWiwJMpqlMeta } from "/usr/src/app/pages/education-category/[slug].vue?macro=true";
import { default as education_45centerCpTV04akDGMeta } from "/usr/src/app/pages/education-center.vue?macro=true";
import { default as _91slug_93cz5QzCijGPMeta } from "/usr/src/app/pages/education-tags/[slug].vue?macro=true";
import { default as _91slug_93Me40sAHKRkMeta } from "/usr/src/app/pages/education/[slug].vue?macro=true";
import { default as errornPjfHBf9BUMeta } from "/usr/src/app/pages/error.vue?macro=true";
import { default as faqsyEOqTc8VH9Meta } from "/usr/src/app/pages/faqs.vue?macro=true";
import { default as forexwyjn9Wk3Y3Meta } from "/usr/src/app/pages/forex.vue?macro=true";
import { default as forgot_45passwordP0Nxfp4jaIMeta } from "/usr/src/app/pages/forgot-password.vue?macro=true";
import { default as glossary8Tgvkjyw1ZMeta } from "/usr/src/app/pages/glossary.vue?macro=true";
import { default as ibHHM57bZs9sMeta } from "/usr/src/app/pages/ib.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as indicesxPLycrV7M4Meta } from "/usr/src/app/pages/indices.vue?macro=true";
import { default as _91slug_93vbvTfepziyMeta } from "/usr/src/app/pages/job-post/[slug].vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as mt5Mw9jaZhBz5Meta } from "/usr/src/app/pages/mt5.vue?macro=true";
import { default as partnershH4za4yi7CMeta } from "/usr/src/app/pages/partners.vue?macro=true";
import { default as register6aSL4CEIZgMeta } from "/usr/src/app/pages/register.vue?macro=true";
import { default as regulationwPjh5SNPj5Meta } from "/usr/src/app/pages/regulation.vue?macro=true";
import { default as stocksH9EHWav1h3Meta } from "/usr/src/app/pages/stocks.vue?macro=true";
import { default as tagsOa7o4FTsiOMeta } from "/usr/src/app/pages/tags.vue?macro=true";
import { default as trading_45basicsl1IEgzQ72XMeta } from "/usr/src/app/pages/trading-basics.vue?macro=true";
import { default as trading_45hoursNubGWyxUf7Meta } from "/usr/src/app/pages/trading-hours.vue?macro=true";
import { default as trading_45toolsTN2TBekkKFMeta } from "/usr/src/app/pages/trading-tools.vue?macro=true";
import { default as webtraderGSBjbD5fJhMeta } from "/usr/src/app/pages/webtrader.vue?macro=true";
import { default as why_45usX0JWEaacS4Meta } from "/usr/src/app/pages/why-us.vue?macro=true";
export default [
  {
    name: "about___en___default",
    path: "/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___en",
    path: "/en/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___es",
    path: "/es/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___it",
    path: "/it/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "about___ar",
    path: "/ar/about",
    component: () => import("/usr/src/app/pages/about.vue")
  },
  {
    name: "account-types___en___default",
    path: "/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___en",
    path: "/en/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___es",
    path: "/es/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___it",
    path: "/it/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "account-types___ar",
    path: "/ar/account-types",
    component: () => import("/usr/src/app/pages/account-types.vue")
  },
  {
    name: "affiliate___en___default",
    path: "/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___en",
    path: "/en/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___es",
    path: "/es/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___it",
    path: "/it/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "affiliate___ar",
    path: "/ar/affiliate",
    component: () => import("/usr/src/app/pages/affiliate.vue")
  },
  {
    name: "app___en___default",
    path: "/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___en",
    path: "/en/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___es",
    path: "/es/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___it",
    path: "/it/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "app___ar",
    path: "/ar/app",
    component: () => import("/usr/src/app/pages/app.vue")
  },
  {
    name: "careers___en___default",
    path: "/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___en",
    path: "/en/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___es",
    path: "/es/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___it",
    path: "/it/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "careers___ar",
    path: "/ar/careers",
    component: () => import("/usr/src/app/pages/careers.vue")
  },
  {
    name: "cfd-asset-list___en___default",
    path: "/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___en",
    path: "/en/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___es",
    path: "/es/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___it",
    path: "/it/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-asset-list___ar",
    path: "/ar/cfd-asset-list",
    component: () => import("/usr/src/app/pages/cfd-asset-list.vue")
  },
  {
    name: "cfd-trading___en___default",
    path: "/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___en",
    path: "/en/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___es",
    path: "/es/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___it",
    path: "/it/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "cfd-trading___ar",
    path: "/ar/cfd-trading",
    component: () => import("/usr/src/app/pages/cfd-trading.vue")
  },
  {
    name: "commodities___en___default",
    path: "/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___en",
    path: "/en/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___es",
    path: "/es/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___it",
    path: "/it/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "commodities___ar",
    path: "/ar/commodities",
    component: () => import("/usr/src/app/pages/commodities.vue")
  },
  {
    name: "contact___en___default",
    path: "/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___en",
    path: "/en/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___it",
    path: "/it/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "contact___ar",
    path: "/ar/contact",
    component: () => import("/usr/src/app/pages/contact.vue")
  },
  {
    name: "cookie-disclosure___en___default",
    path: "/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___en",
    path: "/en/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___es",
    path: "/es/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___it",
    path: "/it/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "cookie-disclosure___ar",
    path: "/ar/cookie-disclosure",
    component: () => import("/usr/src/app/pages/cookie-disclosure.vue")
  },
  {
    name: "copy-trading___en___default",
    path: "/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___en",
    path: "/en/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___es",
    path: "/es/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___it",
    path: "/it/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "copy-trading___ar",
    path: "/ar/copy-trading",
    component: () => import("/usr/src/app/pages/copy-trading.vue")
  },
  {
    name: "crypto___en___default",
    path: "/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___en",
    path: "/en/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___es",
    path: "/es/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___it",
    path: "/it/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "crypto___ar",
    path: "/ar/crypto",
    component: () => import("/usr/src/app/pages/crypto.vue")
  },
  {
    name: "education-authors-slug___en___default",
    path: "/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___en",
    path: "/en/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___es",
    path: "/es/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___it",
    path: "/it/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-authors-slug___ar",
    path: "/ar/education-authors/:slug()",
    component: () => import("/usr/src/app/pages/education-authors/[slug].vue")
  },
  {
    name: "education-category-slug___en___default",
    path: "/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___en",
    path: "/en/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___es",
    path: "/es/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___it",
    path: "/it/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-category-slug___ar",
    path: "/ar/education-category/:slug()",
    component: () => import("/usr/src/app/pages/education-category/[slug].vue")
  },
  {
    name: "education-center___en___default",
    path: "/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___en",
    path: "/en/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___es",
    path: "/es/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___it",
    path: "/it/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-center___ar",
    path: "/ar/education-center",
    component: () => import("/usr/src/app/pages/education-center.vue")
  },
  {
    name: "education-tags-slug___en___default",
    path: "/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___en",
    path: "/en/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___es",
    path: "/es/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___it",
    path: "/it/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-tags-slug___ar",
    path: "/ar/education-tags/:slug()",
    component: () => import("/usr/src/app/pages/education-tags/[slug].vue")
  },
  {
    name: "education-slug___en___default",
    path: "/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___en",
    path: "/en/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___es",
    path: "/es/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___it",
    path: "/it/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "education-slug___ar",
    path: "/ar/education/:slug()",
    component: () => import("/usr/src/app/pages/education/[slug].vue")
  },
  {
    name: "error___en___default",
    path: "/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___en",
    path: "/en/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___es",
    path: "/es/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___it",
    path: "/it/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "error___ar",
    path: "/ar/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "faqs___en___default",
    path: "/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___en",
    path: "/en/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___es",
    path: "/es/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___it",
    path: "/it/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "faqs___ar",
    path: "/ar/faqs",
    component: () => import("/usr/src/app/pages/faqs.vue")
  },
  {
    name: "forex___en___default",
    path: "/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___en",
    path: "/en/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___es",
    path: "/es/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___it",
    path: "/it/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forex___ar",
    path: "/ar/forex",
    component: () => import("/usr/src/app/pages/forex.vue")
  },
  {
    name: "forgot-password___en___default",
    path: "/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___en",
    path: "/en/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___es",
    path: "/es/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___it",
    path: "/it/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "forgot-password___ar",
    path: "/ar/forgot-password",
    component: () => import("/usr/src/app/pages/forgot-password.vue")
  },
  {
    name: "glossary___en___default",
    path: "/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___en",
    path: "/en/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___es",
    path: "/es/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___it",
    path: "/it/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "glossary___ar",
    path: "/ar/glossary",
    component: () => import("/usr/src/app/pages/glossary.vue")
  },
  {
    name: "ib___en___default",
    path: "/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___en",
    path: "/en/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___es",
    path: "/es/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___it",
    path: "/it/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "ib___ar",
    path: "/ar/ib",
    component: () => import("/usr/src/app/pages/ib.vue")
  },
  {
    name: "index___en___default",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___en",
    path: "/en",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___it",
    path: "/it",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "indices___en___default",
    path: "/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___en",
    path: "/en/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___es",
    path: "/es/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___it",
    path: "/it/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "indices___ar",
    path: "/ar/indices",
    component: () => import("/usr/src/app/pages/indices.vue")
  },
  {
    name: "job-post-slug___en___default",
    path: "/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___en",
    path: "/en/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___es",
    path: "/es/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___it",
    path: "/it/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "job-post-slug___ar",
    path: "/ar/job-post/:slug()",
    component: () => import("/usr/src/app/pages/job-post/[slug].vue")
  },
  {
    name: "login___en___default",
    path: "/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___es",
    path: "/es/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___it",
    path: "/it/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___ar",
    path: "/ar/login",
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "mt5___en___default",
    path: "/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___en",
    path: "/en/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___es",
    path: "/es/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___it",
    path: "/it/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "mt5___ar",
    path: "/ar/mt5",
    component: () => import("/usr/src/app/pages/mt5.vue")
  },
  {
    name: "partners___en___default",
    path: "/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___en",
    path: "/en/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___es",
    path: "/es/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___it",
    path: "/it/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "partners___ar",
    path: "/ar/partners",
    component: () => import("/usr/src/app/pages/partners.vue")
  },
  {
    name: "register___en___default",
    path: "/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___es",
    path: "/es/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___it",
    path: "/it/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___ar",
    path: "/ar/register",
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "regulation___en___default",
    path: "/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___en",
    path: "/en/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___es",
    path: "/es/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___it",
    path: "/it/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "regulation___ar",
    path: "/ar/regulation",
    component: () => import("/usr/src/app/pages/regulation.vue")
  },
  {
    name: "stocks___en___default",
    path: "/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___en",
    path: "/en/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___es",
    path: "/es/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___it",
    path: "/it/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "stocks___ar",
    path: "/ar/stocks",
    component: () => import("/usr/src/app/pages/stocks.vue")
  },
  {
    name: "tags___en___default",
    path: "/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___en",
    path: "/en/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___es",
    path: "/es/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___it",
    path: "/it/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "tags___ar",
    path: "/ar/tags",
    component: () => import("/usr/src/app/pages/tags.vue")
  },
  {
    name: "trading-basics___en___default",
    path: "/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___en",
    path: "/en/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___es",
    path: "/es/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___it",
    path: "/it/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-basics___ar",
    path: "/ar/trading-basics",
    component: () => import("/usr/src/app/pages/trading-basics.vue")
  },
  {
    name: "trading-hours___en___default",
    path: "/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___en",
    path: "/en/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___es",
    path: "/es/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___it",
    path: "/it/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-hours___ar",
    path: "/ar/trading-hours",
    component: () => import("/usr/src/app/pages/trading-hours.vue")
  },
  {
    name: "trading-tools___en___default",
    path: "/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___en",
    path: "/en/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___es",
    path: "/es/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___it",
    path: "/it/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "trading-tools___ar",
    path: "/ar/trading-tools",
    component: () => import("/usr/src/app/pages/trading-tools.vue")
  },
  {
    name: "webtrader___en___default",
    path: "/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___en",
    path: "/en/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___es",
    path: "/es/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___it",
    path: "/it/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "webtrader___ar",
    path: "/ar/webtrader",
    component: () => import("/usr/src/app/pages/webtrader.vue")
  },
  {
    name: "why-us___en___default",
    path: "/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___en",
    path: "/en/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___es",
    path: "/es/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___it",
    path: "/it/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  },
  {
    name: "why-us___ar",
    path: "/ar/why-us",
    component: () => import("/usr/src/app/pages/why-us.vue")
  }
]