<script setup lang="ts">
const commonData = inject("commonData");

const { data } = useCustomFetch("/api/strapi/footer");
const { data: footerColumns } = useCustomFetch("/api/strapi/footer-links");
</script>

<template>
  <section v-if="data && footerColumns" id="footer" class="index-footer">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <NuxtLinkLocale to="/" class="ftlogo">
            <img :src="$getStrapiImageSrc(commonData.logoWhite)" />
          </NuxtLinkLocale>

          <div class="downloads footer-downloads">
            <NuxtLinkLocale
              v-for="link in data.downloads"
              :key="`download_${link.id}`"
              :to="link.link"
              :title="link.name"
            >
              <img
                :src="$getStrapiImageSrc(link.image)"
                :alt="link.name"
                width="142"
              />
            </NuxtLinkLocale>
          </div>

          <div v-if="data.contact" class="contactin">
            <h2>
              {{ data.contact.name }}
              <small>
                <template
                  v-for="(contact, index) in data.contact.links"
                  :key="`contact_${contact.id}`"
                >
                  <NuxtLinkLocale :to="contact.url">
                    {{ contact.name }}
                  </NuxtLinkLocale>
                  <br v-if="index + 1 < data.contact.links.length" />
                </template>
              </small>
            </h2>
          </div>
        </div>
        <div class="col-md-9">
          <ul class="ftmenu">
            <li v-for="column in footerColumns" :key="`column_${column.id}`">
              <template
                v-for="(group, index) in column.attributes.linkGroups"
                :key="`group_${group.id}`"
              >
                <h2 :class="{ promoblock: index > 0 }">{{ group.name }}</h2>

                <ul>
                  <li v-for="link in group.links" :key="`link_${link.id}`">
                    <NuxtLinkLocale
                      :to="link.url"
                      :target="link.openInNewTab ? '_blank' : '_self'"
                    >
                      {{ link.name }}
                    </NuxtLinkLocale>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </div>

      <div class="paymentshare">
        <div class="row">
          <div v-if="data.socialsItems" class="col-md-3">
            <p class="share">
              {{ data.socialsTitle || "" }}

              <NuxtLinkLocale
                v-for="item in data.socialsItems"
                :key="`social_${item.id}`"
                :to="item.link"
              >
                <img :src="$getStrapiImageSrc(item.image)" :alt="item.name" />
              </NuxtLinkLocale>
            </p>
          </div>
          <div class="col-md-9">
            <p class="payments">
              <span style="white-space: pre-line">
                {{ data.paymentMethodsTitle || "" }}
              </span>
              <img
                v-for="image in data.paymentMethodsItems?.data"
                :key="`payment-method_${image.id}`"
                :src="$getStrapiImageSrc(image, true)"
              />
            </p>
          </div>
        </div>
      </div>

      <ul v-if="data.additionalLinks" class="usefulelinks">
        <li
          v-for="link in data.additionalLinks"
          :key="`additional-link_${link.id}`"
        >
          <NuxtLinkLocale
            :to="link.url"
            :target="link.openInNewTab ? '_blank' : '_self'"
          >
            {{ link.name }}
          </NuxtLinkLocale>
        </li>
      </ul>

      <div
        v-if="data.text"
        class="disclaimer"
        v-html="$mdRenderer.render(data.text)"
      ></div>
    </div>
  </section>
</template>
